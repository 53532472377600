<template>
  <section class="dashboard">
   
    <div class="page-header">
      <h3 class="page-title">
        <span class="page-title-icon bg-gradient-primary text-white mr-2">
          <i class="mdi mdi-check"></i>
        </span> Agregar {{ $options.label }}</h3>
        
        <!--
          <nav aria-label="breadcrumb">
            <ul class="breadcrumb">
              <li class="breadcrumb-item active" aria-current="page">
                <span></span>Vista general<i class="mdi mdi-alert-circle-outline icon-sm text-primary align-middle"></i>
              </li>
            </ul>
          </nav>
        -->
    </div>


    <div class="row">
      <div class="col-12 grid-margin">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Datos del {{ $options.label }}</h4>


            <treeBudget @onData="onData" :items="items"/>


                <div class="form-group">
                    <submitbutton  :label="'Actualizar'" :loading_flag="loading"/>
                </div>


          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>

import treeBudget from "./treeview";
import submitbutton from '@/components/ui-feature/submit-button';



export default {
  routeName:'budgets',
  label:'Presupuesto',
  name:"updateBudget",
  components: {
    treeBudget,
    submitbutton

  },


  data(){
    return{
      loading:false,
      items:[
          {id:22,
          name:'Preliminares',
          amount:30000
          }
      ]
    }
  },
  methods:{

    onData(data){


        console.log(data);
      
    }

  }

}



</script>


